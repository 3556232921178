<template>
  <div>
    <div v-if="this.isAuthenticated">
      <KTCard ref="preview" v-bind:title="title" v-bind:example="true">
        <template v-slot:title>
          {{ title }}
        </template>
        <template v-slot:body>
          <br />

          <div id="app">
            <b-form class="form" @submit.stop.prevent="onSubmit">
              <div class="row">
                <div class="col-md-6">
                  <b-form-group
                      id="fullName-group"
                      label="Nama Lengkap"
                      label-for="fullName"
                  >
                    <b-form-input
                        class="form-control form-control-solid h-auto py-5 px-6"
                        id="fullName"
                        name="fullName"
                        v-model="$v.form.fullName.$model"
                        v-bind:readonly="true"
                        :state="validateState('fullName')"
                        aria-describedby="fullName-live-feedback"
                        placeholder="Nama Lengkap"
                    ></b-form-input>

                    <b-form-invalid-feedback id="fullName-live-feedback">
                      Masukkan Nama Lengkap Anda.
                    </b-form-invalid-feedback>
                  </b-form-group>
                  <b-form-group
                      id="fullName-group"
                      label="No Identitas"
                      label-for="txtNik"
                  >
                    <b-form-input
                        class="form-control form-control-solid h-auto py-5 px-6"
                        id="txtNoIdentitas"
                        name="txtNoIdentitas"
                        v-model="$v.form.dataNoIdentitas.$model"
                        readonly
                        :state="validateState('dataNoIdentitas')"
                        aria-describedby="dataNoIdentitas-live-feedback"
                        placeholder="No Identitas"
                    ></b-form-input>
                    <b-form-invalid-feedback id="dataNoIdentitas-live-feedback">
                      Masukkan No Identitas Anda.
                    </b-form-invalid-feedback>
                  </b-form-group>
                  <b-form-group
                      id="identity-type-group"
                      label="Tipe Identitas"
                      label-for=""
                  >
                    <b-form-select
                        v-model="$v.form.identityType.$model"
                        :state="validateState('identityType')"
                        :options="identityType"
                        class="form-control form-control-solid h-auto py-5 px-6"
                        value-field="value"
                        text-field="description"
                        disabled-field="notEnabled"
                        disabled
                    >
                      <template #first>
                        <b-form-select-option :value="null"
                        >-- Please select an option --</b-form-select-option
                        >
                      </template>
                    </b-form-select>
                  </b-form-group>
                  <b-form-group id="telepon-group" label="No. Telepon/WA" label-for="telepon">
                    <b-form-input
                        class="form-control form-control-solid h-auto py-5 px-6"
                        id="telepon"
                        name="telepon"
                        v-model="$v.form.telepon.$model"
                        :state="validateState('telepon')"
                        aria-describedby="telepon-live-feedback"
                        placeholder="No. Telepon/WA"
                    ></b-form-input>
                    <b-form-invalid-feedback id="telepon-live-feedback">
                      Masukkan nomor telepon anda.
                    </b-form-invalid-feedback>
                  </b-form-group>
                </div>
                <div class="col-md-6">
                  <b-form-group id="email-group" label="Email" label-for="email">
                    <b-form-input
                        class="form-control form-control-solid h-auto py-5 px-6"
                        id="email"
                        name="email"
                        v-bind:readonly="true"
                        v-model="$v.form.email.$model"
                        :state="validateState('email')"
                        aria-describedby="email-live-feedback"
                        placeholder="Email address"
                    ></b-form-input>
                    <b-form-invalid-feedback id="email-live-feedback">
                      Email is required and a valid email address.
                    </b-form-invalid-feedback>
                  </b-form-group>
                  <b-form-group
                      id="identity-type-group"
                      label="Kategori Pemohon"
                      label-for=""
                  >
                    <b-form-select
                        v-model="$v.form.categoryPemohon.$model"
                        :state="validateState('categoryPemohon')"
                        :options="categoryPemohon"
                        class="form-control form-control-solid h-auto py-5 px-6"
                        value-field="value"
                        text-field="description"
                        disabled-field="notEnabled"
                    >
                    </b-form-select>
                  </b-form-group>
                  <b-form-group
                      id="dateBirth-group"
                      label="Periode data laporan aduan yang dimohon"
                      label-for="txtStartDate"
                  >
                    <datepicker
                        class="form-control form-control-solid h-auto py-5 px-3"
                        v-model="$v.form.startDate.$model"
                        :format="customFormatter"
                        :state="validateState('startDate')"
                        aria-describedby="startDate-live-feedback"
                        minimum-view="month"
                        name="txtStartDate"
                        id="txtStartDate"
                        placeholder="Start period">
                    </datepicker>
                    <b-form-invalid-feedback id="startDate-live-feedback">
                      Masukkan tanggal awal periode
                    </b-form-invalid-feedback>
                    <datepicker
                        class="form-control form-control-solid h-auto py-5 px-3"
                        v-model="$v.form.stopDate.$model"
                        :format="customFormatter"
                        :state="validateState('stopDate')"
                        aria-describedby="stopDate-live-feedback"
                        minimum-view="month"
                        name="txtstopDate"
                        id="txtstopDate"
                        placeholder="End period">
                    </datepicker>
                    <b-form-invalid-feedback id="stopDate-live-feedback">
                      Masukkan tanggal akhir periode
                    </b-form-invalid-feedback>
                  </b-form-group>
                </div>
                <div class="col-md-12">
                  <b-form-group id="dataRincian-group" label="Rincian data pengaduan yang dimohon" label-for="">
                    <b-form-textarea
                        class="form-control form-control-solid h-auto py-5 px-6"
                        id="txtDataRincian"
                        name="txtDataRincian"
                        v-model="$v.form.dataRincian.$model"
                        :state="validateState('dataRincian')"
                        aria-describedby="dataRincian-live-feedback"
                    ></b-form-textarea>
                  </b-form-group>
                  <b-form-group id="dataTujuan-group" label="Tujuan permohonan data aduan" label-for="">
                    <b-form-textarea
                        class="form-control form-control-solid h-auto py-5 px-6"
                        id="txtDataTujuan"
                        name="txtDataTujuan"
                        v-model="$v.form.dataTujuan.$model"
                        :state="validateState('dataTujuan')"
                        aria-describedby="dataTujuan-live-feedback"
                    ></b-form-textarea>
                  </b-form-group>
                </div>

                <div class="col-md-6">
                  <b-form-group
                      id="identity-type-group"
                      label="Cara mengirim bahan informasi"
                      label-for=""
                  >
                    <b-form-select
                        v-model="$v.form.kirimInformasi.$model"
                        :state="validateState('kirimInformasi')"
                        :options="kirimInformasi"
                        class="form-control form-control-solid h-auto py-5 px-6"
                        value-field="value"
                        text-field="description"
                        disabled-field="notEnabled"
                    >
                      <template #first>
                        <b-form-select-option :value="null"
                        >-- Please select an option --</b-form-select-option
                        >
                      </template>
                    </b-form-select>
                  </b-form-group>
                </div>
                <div class="col-md-6">
                <b-form-group
                    id="idimage-group"
                    label-for=""
                    label="Surat Pengantar Instansi"
                >
                <span id="previewIdentitas">
                </span>
                  <b-form-file
                      id="dokumen"
                      name="dokumen"
                      v-model="$v.form.dokumen.$model"
                      :state="validateState('dokumen')"
                      aria-describedby="idimage-live-feedback"
                      placeholder="Pilih dokumen"
                  >
                  </b-form-file>
                </b-form-group>
                </div>
              </div>
              <br/><br/>

              <div class="row">
                <!--begin::Action-->
                <div class="col-md-12">
                  <p align="center">
                    <input
                        type="checkbox"
                        v-model="checked"
                        name="agreeTerms"
                        id="agreeTerms"
                    />&nbsp;{{ $t("COMPLAINT_DATA_APPLICATION.AGREE_TERMS") }}
                    <br /><br />
                    <button
                        v-on:click="onSubmitData"
                        v-bind:disabled="checked === false"
                        type="submit"
                        class="btn btn-primary"
                        v-bind:class="[
                          loading ? 'spinner spinner-light spinner-right' : ''
                        ]"
                    >
                      <img
                          src="media/image/paperplane.png"
                          style="width: 30px; margin-right: 10px"
                      />{{ $t("COMPLAINT_DATA_APPLICATION.SUBMIT") }}
                    </button>
                    <button
                        class="btn btn-light-primary font-weight-bold px-9 py-4 my-3 font-size-3 mx-4"
                        v-on:click="cancelEdit()"
                    >
                      Cancel
                    </button>
                  </p>
                  <!--end::Action-->
                </div>
              </div>
            </b-form>
          </div>
          <br>

        </template>
      </KTCard>
    </div>
    <div v-if="!this.isAuthenticated">
      <div class="card card-custom card-stretch gutter-b">
        <div class="card-body d-flex p-0">
          <div
              class="flex-grow-1 p-20 pb-40 card-rounded flex-grow-1 bgi-no-repeat"
              style="background-position: calc(100% + 0.5rem) bottom; background-size: 35% auto; background-image: url(media/svg/humans/custom-10.svg)"
          >
            <h2 class="text-dark pb-5 font-weight-bolder">
              {{ $t("COMPLAINT_RECEIPT_DATA.REGISTER_NOW_TITLE") }}
            </h2>
            <p class="text-dark-50 pb-5 font-size-h5">
              {{ $t("COMPLAINT_RECEIPT_DATA.REGISTER_NOW") }}<br />
              {{ $t("COMPLAINT_RECEIPT_DATA.REGISTER_NOW_SECOND_PARAGRAPH") }}
            </p>
            <a
                @click="registerClicked"
                class="btn btn-danger font-weight-bold py-2 px-6"
            >{{ $t("COMPLAINT_RECEIPT_DATA.REGISTER_NOW_TITLE") }}</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  GET_IDENTITY_TYPE,
  GET_CATEGORY_PEMOHON,
  GET_KIRIM_INFORMASI,
  SET_IDENTITY_TYPE,
  SET_CATEGORY_PEMOHON,
  SET_KIRIM_INFORMASI
} from "@/core/services/store/auth.module";
import KTCard from "@/view/content/Card.vue";
import { validationMixin } from "vuelidate";
import {email, required, minLength} from "vuelidate/lib/validators";
import * as axios from "axios";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Datepicker from 'vuejs-datepicker';
import moment from 'moment';
import JwtService from "@/core/services/jwt.service";
import ApiService from "@/core/services/api.service";
import Swal from "sweetalert2";

export default {
  mixins: [validationMixin],
  name: "complaint-data-application-eksternal",
  data() {
    return {
      checked: false,
      loading: false,
      url: null,
      title: "Formulir Permohonan Eksternal",
      pathIdentity: "",
      pathProfile: "",
      form: {
        email: "",
        fullName: "",
        startDate: "",
        stopDate: "",
        identityType: null,
        categoryPemohon: null,
        kirimInformasi: null,
        telepon: "",
        address: null,
        dokumen: null,
        dataRincian: null,
        dataTujuan: null,
        dataNik: null,
        dataNoIdentitas: null
      },
      logo: process.env.BASE_URL + "media/logos/komnasham.png",
      identityType: [],
      categoryPemohon: [],
      kirimInformasi: [],
      userInfo: null
    };
  },
  validations: {
    form: {
      email: {
        required,
        email
      },
      fullName: {
        required,
        minLength: minLength(5)
      },
      identityType: {
        required
      },
      categoryPemohon: {
        required
      },
      kirimInformasi:{
        required
      },
      telepon: {
        required
      },
      dataRincian: {
        required,
        minLength: minLength(5)
      },
      dataTujuan: {
        required,
        minLength: minLength(5)
      },
      dataNoIdentitas: {
        required
      },
      startDate: {
        required
      },
      stopDate: {
        required
      },
      dokumen: {
        required
      }
    }
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: this.$t("MENU.COMPLAINT_DATA"),
        route: "complaint-receipt-data",
      },
      { title: this.$t("MENU.COMPLAINT_RECEIPT_DATA_FORM") },
    ]);
    this.$store.dispatch(GET_IDENTITY_TYPE);
    this.$store.dispatch(GET_CATEGORY_PEMOHON);
    this.$store.dispatch(GET_KIRIM_INFORMASI);
    this.bindingData();
    this.getUserData();
  },
  methods: {
    padTo2Digits(num) {
      return num.toString().padStart(2, '0');
    },
    formatDate(date, delimiter) {
      return [
        date.getFullYear(),
        this.padTo2Digits(date.getMonth() + 1),
      ].join(delimiter);
    },
    customFormatter(date) {
      return moment(date).format('MMMM YYYY');
    },
    onSubmitData() {
      // console.log("Sample data");
    },
    getViewData(){
      return new Promise((resolve, reject) => {
        ApiService.setHeader();
        ApiService.get("user/smartmapinfo")
            .then(({ response }) => {
              this.$v.form.email.$model = response.data[0].email;
              this.$v.form.fullName.$model = response.data[0].name;
              this.$v.form.identityType.$model = response.data[0].identity_type_id;
              this.$v.form.telepon.$model = response.data[0].phone_no;
              this.$v.form.dataNoIdentitas.$model = response.data[0].identity_no;;
              resolve(response);
            })
            .catch(({ error }) => {
              reject(error);
            });
      });
    },
    checkbox() {
      this.checked = true;
    },
    getUserData() {
      axios({
        url:
            process.env.VUE_APP_API_BASE_URL + "api/user/smartmapinfo",
        method: "GET",
        headers: {
          Authorization: 'Bearer ' + JwtService.getToken()
        }
      }).then((response) => {
        this.$v.form.email.$model = response.data.data[0].email;
        this.$v.form.fullName.$model = response.data.data[0].name;
        this.$v.form.identityType.$model = response.data.data[0].identity_type_id;
        this.$v.form.telepon.$model = response.data.data[0].phone_no;
        this.$v.form.dataNoIdentitas.$model = response.data.data[0].identity_no;;
      }).catch(({ error }) => {
        this.userInfo = JSON.parse(window.localStorage.getItem("user_info"));
        if(this.userInfo != null){
          this.getParseData(this.userInfo);
        }else{
          console.log(error);
        }
      });
    },
    getParseData(response){
      this.$v.form.email.$model = response.data[0].email;
      this.$v.form.fullName.$model = response.data[0].name;
      this.$v.form.identityType.$model = response.data[0].identity_type_id;
      this.$v.form.telepon.$model = response.data[0].phone_no;
    },
    onFileChange(e) {
      const file = e.target.files[0];
      this.url = URL.createObjectURL(file);
    },
    bindingData() {
      this.unsubscribe = this.$store.subscribe(mutation => {
        if (mutation.type === SET_IDENTITY_TYPE) {
          this.identityType = this.getIdentityType;
        }
        if (mutation.type === SET_CATEGORY_PEMOHON) {
          this.categoryPemohon = this.getCategoryPemohon;
        }
        if (mutation.type === SET_KIRIM_INFORMASI) {
          this.kirimInformasi = this.getKirimInformasi;
        }
      });
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    goToEditmode() {
      this.editmode = true;
    },
    cancelEdit() {
      this.$router.push("complaint-data-application");
    },
    chooseFile(event) {
      let input = event.target.files[0];
      let validFormat = this.validate_fileupload(input);
      if (validFormat == false) {
        Swal.fire("Format File", "File format not supported", "error");
        return;
      }
    },
    validate_fileupload(fileName) {
      var allowed_extensions = new Array(
          "jpg",
          "png",
          "gif",
          "jpeg"
      );
      var file_extension = fileName.split(".").pop().toLowerCase();

      for (var i = 0; i <= allowed_extensions.length; i++) {
        if (allowed_extensions[i] == file_extension) {
          return true; // valid file extension
        }
      }
      return false;
    },
    createDownloadUrl(fileName) {
      return (
          process.env.VUE_APP_API_BASE_URL +
          "api/smartmap/download-profile/" +
          fileName
      );
    },
    onSubmit() {
      if (this.loading) {
        Swal.fire("Formulir Permohonan Eksternal", "Dokumen sedang proses registrasi", "warning");
        return false;
      }
      this.loading = true;
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        // this.loading = false;
        Swal.fire("Formulir Permohonan Eksternal", "Isi semua form dengan benar", "error");
        return false;
      }

      let formEditProfile = new FormData();
      formEditProfile.set("full_name", this.$v.form.fullName.$model);
      formEditProfile.set("telepon", this.$v.form.telepon.$model);
      formEditProfile.set("no_identitas", this.$v.form.dataNoIdentitas.$model);
      formEditProfile.set("email", this.$v.form.email.$model);
      formEditProfile.set("category_pemohon", this.$v.form.categoryPemohon.$model);
      formEditProfile.set("kirim_informasi", this.$v.form.kirimInformasi.$model);
      formEditProfile.set("identity_type", this.$v.form.identityType.$model);
      formEditProfile.set("data_rincian", this.$v.form.dataRincian.$model);
      formEditProfile.set("data_tujuan", this.$v.form.dataTujuan.$model);
      formEditProfile.set("period_start", this.formatDate(this.$v.form.startDate.$model, ""));
      formEditProfile.set("period_stop", this.formatDate(this.$v.form.stopDate.$model, ""));
      formEditProfile.append(
          "request_proposal_file",
          this.$v.form.dokumen.$model
      );
      axios({
        url:
            process.env.VUE_APP_API_BASE_URL + "api/smartmap/request-data-eksternal",
        method: "POST",
        data : formEditProfile,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: 'Bearer ' + JwtService.getToken()
        }
        })
        .then((response) => {
          this.loading = false;
          if(response.data.message == 'failed'){
            Swal.fire("Register failed", response.data.data[0], "error");
          }else if(response.data.message == 'success'){
            Swal.fire("Formulir Permohonan Eksternal", "Dokumen Formulir Permohonan Eksternal berhasil disimpan", "success");
            this.$router.push("complaint-data-application");
          }else{
            Swal.fire("Register failed", "Server not response", "error");
          }
        }).catch(() => {
          this.loading = false;
          Swal.fire("Formulir Permohonan Eksternal", "Dokumen Formulir Permohonan Eksternal gagal disimpan", "error");
      });
    },
    registerClicked() {
      this.$router.push({ name: "register" });
    }
  },
  components: {
    KTCard,
    Datepicker
  },
  computed: mapGetters([
    "isAuthenticated",
    "getIdentityType",
    "getCategoryPemohon",
    "getKirimInformasi"
  ])
};
</script>

